import { PlainObject } from 'shared/util/types.util'
import { TrackerWithAutoTracking } from 'analytics/OmniTracker/Tracker'
import { RumGlobal } from '@datadog/browser-rum'
import { LogsGlobal } from '@datadog/browser-logs'

export default class DatadogTracker implements TrackerWithAutoTracking {
  private realUserMonitoring: RumGlobal

  private logs: LogsGlobal

  constructor(rum: RumGlobal, logs: LogsGlobal) {
    this.realUserMonitoring = rum
    this.logs = logs
  }

  trackEvent(name: string, payload: PlainObject): void {
    this.realUserMonitoring.addAction(name, payload)
  }

  trackView() {
    this.realUserMonitoring.startView()
  }

  resetSession(): void {
    this.realUserMonitoring.stopSession()
    this.realUserMonitoring.startSessionReplayRecording()
  }

  setAutoTrackingProperties(properties: PlainObject): void {
    this.realUserMonitoring.setGlobalContext(properties)
    this.logs.setGlobalContext(properties)
  }
}
