import { pick } from 'lodash'
import $ from 'jquery'
import 'analytics/ahoy'
import 'analytics/elastic_apm_rum'
import 'analytics/posthog'
import 'analytics/datadog'
import Tracker from 'analytics/tracker'
import AutoTracking from 'analytics/OmniTracker/AutoTracking'
import IntercomTracker from 'analytics/OmniTracker/IntercomTracker'
import LocalTracker from 'analytics/OmniTracker/LocalTracker'

AutoTracking.trackClicks('a, button, input[type=submit]')
AutoTracking.trackSubmits('form')
AutoTracking.trackChanges('input, textarea, select')

$(document).ready(() => {
  Tracker.addGlobalContext(window.analytics.context)
  Tracker.addGlobalContext({
    userId: window.analytics.user?.id,
    ...pick(window.analytics.user, 'company_id', 'company_user_role'),
  })

  Tracker.addTracker(LocalTracker)

  // Intercom is loaded async, though we'll add the corresponding tracker in any case.
  // Even if Intercom turns out not to be loaded, the tracker methods will act as no-ops.
  Tracker.addTracker(new IntercomTracker())
})
