import { init as initApm } from '@elastic/apm-rum'

import AnalyticsData from 'analytics/AnalyticsData'
import { ObjectWithAnyProps } from 'interfaces/generalTypes'

window.addEventListener('DOMContentLoaded', () => {
  const analyticsData: AnalyticsData = (window as ObjectWithAnyProps).analytics

  if (analyticsData.init.elasticApmRum != null) {
    const user = { ...analyticsData.user, username: analyticsData.user?.name }
    const apm = initApm(analyticsData.init.elasticApmRum)
    apm.setUserContext(user)
    apm.setCustomContext(analyticsData.context)
  } else {
    console.warn('Elastic analytics data not found, skipping initialization.')
  }
})
