/**
 * This file implements auto-tracking for OmniTracker. All click, input and change events can be registered
 * and sent to OmniTracker automatically. Additionally, it will include context information, such as the
 * the app and anything passed to ProvideTrackerContext.
 *
 * Shamelessly "borrowed" from ahoy.js.
 */

import tracker from 'analytics/tracker'
import { Optional, PlainObject } from 'shared/util/types.util'
import { pickBy } from 'lodash'

function closestAttribute(element: Element, attribute: string): Optional<string> {
  return element.closest(`[${attribute}]`)?.getAttribute(attribute)
}

function findTrackerContext(element: Element): PlainObject {
  const rawJson = closestAttribute(element, 'data-tracking-context')
  if (rawJson == null) return {}
  return JSON.parse(rawJson)
}

function onEvent(
  eventName: string, selector: string, callback: (element: Element, event: Event) => void,
) {
  document.addEventListener(eventName, (e) => {
    const element = e.target as Element
    if (element == null || typeof element.closest !== 'function') return

    if (closestAttribute(element, 'data-auto-track') === 'false') return

    const matchedElement = element.closest(selector)
    if (matchedElement != null) {
      callback(matchedElement, e)
    }
  })
}

function getEventPayload(element: Element): PlainObject {
  const trackValue = closestAttribute(element, 'data-auto-track-value') === 'true'
  return pickBy({
    ...findTrackerContext(element),
    tag: element.tagName.toLowerCase(),
    id: element.id,
    class: element.className,
    page: window.location.pathname,
    url: window.location.href,
    section: closestAttribute(element, 'data-section'),
    ...(trackValue && { value: extractValue(element as HTMLInputElement) }),
  }, (val) => val !== undefined)
}

function extractText(element: HTMLElement) {
  return (element.textContent || element.innerText).replace(/[\s\r\n]+/g, ' ').trim()
}

function extractValue(element: HTMLInputElement) {
  if (element.type === 'checkbox' || element.type === 'radio') {
    return element.checked
  }
  return element.value
}

export default Object.freeze({
  trackClicks(selector: string) {
    onEvent('click', selector, (element) => {
      const payload = getEventPayload(element)
      payload.text = extractText(element as HTMLElement)
      payload.href = (element as HTMLAnchorElement).href
      tracker.trackEvent({ action: '$click', payload: pickBy(payload), automated: true })
    })
  },

  trackSubmits(selector: string) {
    onEvent('submit', selector, (e) => {
      const payload = getEventPayload(e)
      tracker.trackEvent({ action: '$submit', payload, automated: true })
    })
  },

  trackChanges(selector: string) {
    onEvent('change', selector, (e) => {
      const payload = getEventPayload(e)
      tracker.trackEvent({ action: '$change', payload, automated: true })
    })
  },
})
