import { Maybe, PlainObject } from 'shared/util/types.util'
import Tracker from 'analytics/OmniTracker/Tracker'
import { ObjectWithAnyProps } from 'interfaces/generalTypes'

type IntercomInterface = (action: string, ...args: unknown[]) => void

let Intercom: Maybe<IntercomInterface>

function intercomInstance(): Maybe<IntercomInterface> {
  Intercom ||= (window as ObjectWithAnyProps).Intercom
  return Intercom
}

function intercomInstanceValid(): boolean {
  return typeof intercomInstance() === 'function'
}

function runIntercomAction(action: string, ...args: unknown[]): void {
  if (intercomInstanceValid()) {
    intercomInstance()?.(action, ...args)
  }
}

export default class IntercomTracker implements Tracker {
  trackEvent(name: string, payload: PlainObject): void {
    if (this.shouldTrackEvent(name)) {
      // We can only track 120 events in intercom.
      // Thus, we just track this as `frontend-tracker-event` in intercom
      // and send the event-name with the payload
      payload.eventName = name
      runIntercomAction('trackEvent', 'frontend-tracker-event', payload)
    }
  }

  trackView() {
    // No-op
  }

  resetSession(): void {
    runIntercomAction('update')
  }

  private shouldTrackEvent(name: string): boolean {
    // Shall not include any auto-tracking events
    return name.indexOf('$') !== 0
  }
}
